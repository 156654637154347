import app from "@/main";
import TemelBilgileri from "./TemelBilgileri";
import HesapBilgisi from "./HesapBilgisi";
import Adres from "./Adres";
import {TakipRol} from "./TakipRolleri";
import {Mixins} from "../Mixins";
import {MessageLogger} from "../MessageLogger";
import Vekil from "./Vekil";
import {AvukatSorgulamaCevap} from "../../uyap/AvukatSorgulama";
import {DosyaFormDataInterface} from "../DosyaFormDataInterface";
import {MtsDosyaFormData} from "../MtsDosyaFormData";
import {IbanTercihiEnum} from "../../uyap/IbanTercihiEnum";

export default class TarafInterface extends MessageLogger {
    refId: string = "";
    tarafSifati: TakipRol = new TakipRol();
    adSoyad: string = "";
    kurumAdi: string = "";
    mersisNo: string = "";
    detsisNo: string = "";
    vergiNo: string = "";
    sorguTuru!: number;
    tarafTuru: string = ""; // KISI || KURUM
    temelBilgileri: TemelBilgileri = new TemelBilgileri();
    mernisAdresiKullan: boolean = false;
    alacakliMernisAdresiKullanilsin: boolean = false;
    adresBilgisi: Adres = new Adres();
    hesapBilgisi!: HesapBilgisi;
    isVekilIban: boolean = false;
    //MTS Different elements
    rolGirisBilgisi!: TakipRol;
    vekilListesi: AvukatSorgulamaCevap[] = [];
    ibanList: {
        kaynak: string,
        kisi: string,
        hesapBilgisi: HesapBilgisi
    }[] = [];
    selectedIbanIndex: number = 0;
    adresList: Adres[] = [];//[]; //adres birden fazla geliyorsa bu array da tutuyorum uyapla alakasi yok
    adresListPickedIndex: number = 0;
    tarafHashList: any[] = [];
    vekilListesiFromDom: any[] = [];
    alacakliIletisimEPosta: string = "";//"";
    alacakliIletisimTelefon: string = "";//"";

    isMts: boolean = false;
    kurumMu: boolean = false;
    kisiMi: boolean = false;
    mernisAdresiVarMi: boolean = false;
    formData!: DosyaFormDataInterface | MtsDosyaFormData;

    isKurum() {
        return this.kurumMu;
    }

    isKisi() {
        return this.kisiMi;
    }

    setAlacakliMernisAdresiTercihi(alacakliMernisAdres: boolean): void {
        this.alacakliMernisAdresiKullanilsin = alacakliMernisAdres;
    }

    setMernisAdresiTercihi(mernisKullanilsinMiBool: boolean): void {
        this.mernisAdresiKullan = mernisKullanilsinMiBool;
    }

    getMernisAdresTercihi() {
        return this.mernisAdresiKullan;
    }

    getAlacakliMernisAdresTercihi() {
        return this.alacakliMernisAdresiKullanilsin;
    }

    isBorclu() {
        return this.tarafSifati.rolID == 22;
    }

    isAlacakli() {
        return this.tarafSifati.rolID == 21;
    }

    async setVekilListesi(): Promise<void> {
        console.log("Set vekil listesi called", this.vekilListesiFromDom);
        try {
            for (const vekil of this.vekilListesiFromDom) {
                try {
                    let vekilObj = Vekil.fromJson(vekil);
                    await vekilObj.runQuery();
                    if (vekilObj.vekil) {
                        this.vekilListesi.push(vekilObj.vekil);
                    }
                    //@ts-ignore
                } catch (e) {
                    console.error("HATA VekilListesi" + e.message);
                    this.addMessage("error", "Vekil Bulunamadı HATA - " + e.message);
                    throw new Error(e.message);
                }
            }
            //@ts-ignore
        } catch (e) {
            throw new Error(e.message);
        }

    }

    toUyapJson(key: number | null = null) {
        if (this.isMts)
            return this.mtsToUyapJson(key);
        const {
            vekilListesi,
            tarafSifati,
            sorguTuru,
            tarafTuru,
            temelBilgileri,
            mernisAdresiKullan,
            adresBilgisi,
            hesapBilgisi,
            ibanList,
            adresList,
            isVekilIban,
            adresListPickedIndex,
            mernisAdresiVarMi,
            selectedIbanIndex,
        } = this;

        let data: any = {
            vekilListesi,
            tarafSifati,
            sorguTuru,
            tarafTuru,
            temelBilgileri,
            mernisAdresiKullan,
            mernisAdresiVarMi,
            adresBilgisi,
            adresList,
            adresListPickedIndex,
            hesapBilgisi,
            ibanList,
            selectedIbanIndex,
            isVekilIban,

        };

        if (tarafTuru == 'KISI')
            data['hesapBilgisi'] = hesapBilgisi
        if (tarafTuru == 'KURUM')
            data['adresBilgisi'] = adresBilgisi


        //iban yoksa silsin hesap bilgisini
        // hesapBilgisi: {ibanNumarasi:''} ,

        // if(this.formData.ibanTercihi == IbanTercihiEnum.Yok){
        //     data.hesapBilgisi = {
        //         ibanNumarasi:''
        //     }
        // }

        if (data.hesapBilgisi.kayitID == '' || data.hesapBilgisi.kayitID == null || this.formData.ibanTercihi == IbanTercihiEnum.Yok) {
            delete data.hesapBilgisi;
            delete data.isVekilIban;
            delete data.selectedIbanIndex;
        }
        return data;
    }

    mtsToUyapJson(key: any) {
        const {
            temelBilgileri,
            mernisAdresiKullan,
            adresBilgisi,
            hesapBilgisi,
            isVekilIban,
            tarafSifati,
            tarafTuru,
            alacakliIletisimEPosta,
            alacakliIletisimTelefon
        } = this;
        temelBilgileri.kisiKurumId = temelBilgileri.kisiKurumID;
        let data: any = {
            viewId: tarafTuru.toLowerCase() + "_" + key,
            temelBilgiler: temelBilgileri,
            mernisAdresiKullan: mernisAdresiKullan,
            adresList: [adresBilgisi],
            rolGirisBilgisi: tarafSifati,
            currentStatus: {
                tarafHesapBilgisi: {
                    selectedHesapBilgileriDVOList: []
                },
                vekilHesapBilgisi: {
                    selectedHesapBilgileriDVOList: []
                }
            }
        }

        if (alacakliIletisimEPosta != "" && alacakliIletisimTelefon != "") {
            data["alacakliIletisimEPosta"] = alacakliIletisimEPosta;
            data["alacakliIletisimTelefon"] = alacakliIletisimTelefon;
        }

        if (hesapBilgisi.ibanNumarasi != "" && hesapBilgisi.ibanNumarasi != null) {
            if (this.isAlacakli() && this.formData.ibanTercihi == IbanTercihiEnum.Avukat)
                data.currentStatus.vekilHesapBilgisi.selectedHesapBilgileriDVOList.push(hesapBilgisi);
            else
                data.currentStatus.tarafHesapBilgisi.selectedHesapBilgileriDVOList.push(hesapBilgisi);
        }
        console.log("TARAF MTS TO JSON DATA", data);
        return data;
    }

    setAddressIndex(index: number) {
        if (this.adresList[index]) {
            this.adresBilgisi = this.adresList[index];
            if (this.adresBilgisi.adresTuru == "ADRTR00013")//mernis adresi
                this.setMernisAdresiTercihi(true);
            else
                this.setMernisAdresiTercihi(false);
            this.adresListPickedIndex = index;
            return this.adresBilgisi
        } else {
            return {
                message: "Seçili index'de adres bulunamadı!",
                adresList: this.adresList
            }
        }
    }

    setIbanIndex(index: number) {
        if (this.ibanList[index]) {
            this.hesapBilgisi = this.ibanList[index].hesapBilgisi;
            this.selectedIbanIndex = index;
            return this.hesapBilgisi
        } else {
            return {
                message: "Seçili index'de iban bulunamadı!",
                ibanList: this.ibanList
            }
        }
    }

    setVekil(tarafDomNode: Element, vekilList: Vekil[]) {
        console.log("SET VEKIL");
        if (!this.isMts && !this.isAlacakli()) {
            let vekilRefIds: any[] = [];
            tarafDomNode.querySelectorAll('ref').forEach(
                (refNode) => {
                    vekilRefIds.push(refNode.getAttribute('id'))
                });
            console.log("VEKIL LIST", vekilList);
            console.log("vekilRefIds LIST", vekilRefIds);
            vekilList.forEach(vekil => {
                if (vekilRefIds.find(id => id == vekil.refId))
                    this.vekilListesiFromDom.push(vekil);
            });
        }
    }

    async runQueryForMtsHesapBilgisi(): Promise<void> {
        console.log("runQueryForMTSHesapBilgisi ");
        try {
            let ibanNo = Mixins.onlyDigit(this.hesapBilgisi.ibanNumarasi);
            let hesapBilgisi = await app.$uyap.GetIbanDetails().run({iban: ibanNo, isSansurlenecek: false});
            if (hesapBilgisi.bankaAdi.trim() != "T.VAKIFLAR BANKASI T.A.O.") {
                //return rej()
            }
            this.hesapBilgisi = hesapBilgisi;
            return Promise.resolve();
        } catch (err) {
            console.log("IBAN runQueryForHesapBilgisi ERR", err);
            return Promise.reject(err);
        }
    }

    async runQueryForHesapBilgisi(): Promise<void> {
        console.log("runQueryForHesapBilgisi 1111");
        return new Promise(async (resolve, reject) => {
            try {
                let ibanTercihi = this.formData.ibanTercihi;
                // vekilin iban'nı uyap'tan almış ol
                let ibanNo = Mixins.onlyDigit(this.hesapBilgisi.ibanNumarasi);
                let ibanList = await app.$uyap.AvukatKayitliIbanList().run({});
                this.ibanList = ibanList.value.map((avukatIban: any) => {
                    return {
                        hesapBilgisi: avukatIban,
                        kaynak: 'UYAP',
                        kisi: 'Avukat'
                    }
                });
                console.log("avukat eklendi", ibanList)
                console.log("TERCİH ", ibanTercihi);
                console.log("TERCİH ?", ibanTercihi == IbanTercihiEnum.Avukat);
                if (ibanTercihi == IbanTercihiEnum.Avukat) {
                    this.selectedIbanIndex = this.ibanList.findIndex(v => v.hesapBilgisi.ibanNumarasi == this.formData.avukatIban);
                    this.hesapBilgisi = this.ibanList[this.selectedIbanIndex].hesapBilgisi;
                    this.isVekilIban = true;
                    return resolve();
                }
                let xmlIbaniBulundu = false;
                if (ibanNo != "") {
                    app.$uyap.GetIbanDetails().run({
                        iban: ibanNo,
                        isSansurlenecek: false
                    }).then((hesapBilgisi: HesapBilgisi) => {
                        this.ibanList.push(
                            {
                                hesapBilgisi: hesapBilgisi,
                                kaynak: "XML",
                                kisi: IbanTercihiEnum.Muvekkil
                            }
                        );
                        console.log("RESILVE XML IBAN");
                        this.hesapBilgisi = hesapBilgisi;
                        this.isVekilIban = false;
                        this.selectedIbanIndex = this.ibanList.findIndex(v => v.kaynak == "XML");
                        xmlIbaniBulundu = true;
                        return resolve();
                    });
                    console.log("xmldeki eklendi")
                }
                let uyapIbanList = await app.$uyap.GetIbanListesi().run({
                    kisiKurumId: this.temelBilgileri.kisiKurumID,
                    tarafTur: 1
                });
                // alacaklının uyap'tan
                uyapIbanList.forEach((item: HesapBilgisi) => {
                    this.ibanList.push({
                        hesapBilgisi: item,
                        kaynak: 'UYAP',
                        kisi: IbanTercihiEnum.Muvekkil
                    })
                });
                console.log("sorgulardaki eklendi");

                console.log("ibanlist", ibanList)
                if (this.ibanList.length > 0) {
                    if (!xmlIbaniBulundu) {
                        this.selectedIbanIndex = 0;
                        this.hesapBilgisi = this.ibanList[0].hesapBilgisi;
                        this.isVekilIban = false;
                    }
                    console.log("runQueryForHesapBilgisi resolve");
                    return resolve();
                } else {
                    return reject({message: "İban bilgisi hatalı: İban bulunamadı"});
                }
            } catch (err) {
                console.log("IBAN runQueryForHesapBilgisi ERR", err);
                return reject(err);
            }
        })
    }
}

